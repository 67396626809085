<template>
  <div class="customer-service">
    <common-header class="header" navName="customerService" :show="display" />
    <div :class="['page-wraper', pageName]">
      <div class="main-box">
        <about-side-bar @pageName="getPageName" @loading="displayShow" />
        <router-view @loading="displayShow" class="content"></router-view>
      </div>
    </div>
    <common-footer v-show="display" />
    <Loading v-show="!display" />
    <Service />
  </div>
  <!-- <div  :class="['about-us', pageName]">    
    <template v-show="display">
    <common-header class="header" :show="display" />
    <div :class="['page-wraper', pageName]">
      <div :style="{height: display ? 'auto' : '200vh'}" :class="['main-box', pageName]">
        <about-side-bar @pageName="getPageName" @loading="displayShow" /> -->
  <!-- <router-view @loading="displayShow" class="content"></router-view> -->
  <!-- </div> -->
  <!-- </div>
    <common-footer v-show="display" />
    </template> -->
  <!-- <Service />
    <Loading v-show="!display" /> -->
  <!-- </div> -->
</template>
<script>
import CommonHeader from '@/components/common/Header.vue'
import CommonFooter from '@/components/common/Footer.vue'
import Loading from '@/components/common/Loading.vue'
import Service from '@/components/common/Service.vue'

import AboutSideBar from '@/components/customerService/sideBar.vue'
import { isMobile } from '@/helper/utils'
export default {
  components: {
    CommonHeader,
    CommonFooter,
    AboutSideBar,
    Loading,
    Service
  },
  data() {
    return {
      display: false,
      pageName: ''
    }
  },
  beforeCreate() {
    document.body.scrollTop = document.documentElement.scrollTop = 0
    if (isMobile()) {
      document.querySelector('html').style.fontSize = '71px'
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === '/') {
      var docEI = document.documentElement;
      var clientWidth = docEI.clientWidth
      docEI.style.fontSize = 100 * (clientWidth / 1920) + 'px'
    }
    next();
  },
  methods: {
    displayShow(loading) {
      this.display = !loading

      var Element = document.querySelector('.mobile-header .head')
      if (Element) {
        var headerHeight = Element.offsetHeight
        document.getElementById('app').style.paddingTop = headerHeight + 'px'
      }
    },
    // 获取当前页面名称
    getPageName(pageName) {
      this.pageName = pageName
    }
  }
}
</script>
<style lang="scss" scoped>
.customer-service {
  // min-height: 110vh;
}
.about-us {
  &.Introduction {
    background-image: url(../../assets/img/aboutus/introductionbg.jpg);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-color: #fafcfc;
  }
}
.page-wraper {
  padding-bottom: 1rem;
  &.notification,
  &.agreement {
    background-image: url(../../assets/img/customerService/bottom_bg.jpg);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: left bottom;
    // padding-bottom: 4.5rem;
  }
}
.header {
  display: block !important;
}
.main-box {
  max-width: 14rem;
  margin: 0 auto 0;
  padding-top: 1.26rem;
  display: flex;
  .content {
    flex: 1;
    // overflow-x: hidden;
    // overflow-y: hidden;
  }
}

@media only screen and (max-width: $moble_width) {
  .page-wraper {
    padding-bottom: 0;
  }
  .main-box {
    width: 100%;
    padding-top: 0;
    padding: 0 15px;
  }
  .about-side-bar {
    display: none;
  }
}
</style>
