<!--
 * @Author       : your name
 * @Date         : 2020-11-24 22:58:26
 * @LastEditTime : 2020-12-01 17:54:27
 * @LastEditors  : Please set LastEditors
 * @Description  : In User Settings Edit
 * @FilePath     : /mingya/src/components/common/Service.vue
-->
<template>
  <aside :class="[isMobile ? 'mobile-servece' : 'pc-servece']">
    <div v-if="!visible" class="m-service" @click="visible=true">
      <img src="@/assets/img/common/online.png" alt="" />
    </div>
    <div v-if="visible" class="service">
      <!-- <img @click="visible=false" class="hide" v-if="visible && isMobile" src="@/assets/img/common/close.png" alt=""> -->
      <div @click="visible=false" v-if="visible" class="hide">
        <img src="@/assets/img/common/common_add_white.png" alt="">
      </div>
      <div class="online">
<!--        <a href="https://mybx.qiyukf.com/client?k=3c8895c6a0ef81d5d7624a1e50d82a68&wp=1&gid=1144605" target="_blank">-->
        <a href="https://v7-webchat.7moor.com/?accessId=ILwj5de78f54oT74&imUrlTitle=&imFromUrl=" target="_blank">
          <img src="@/assets/img/common/online.png" alt="" /></a>

<!--        <a href="https://mybx.qiyukf.com/client?k=3c8895c6a0ef81d5d7624a1e50d82a68&wp=1&gid=1144605" target="_blank"><span class="text">在线客服</span></a>-->
        <a href="https://v7-webchat.7moor.com/?accessId=ILwj5de78f54oT74&imUrlTitle=&imFromUrl=" target="_blank"><span class="text">在线客服</span></a>
      </div>
      <!-- <div class="ai">
        <a href="http://chat.mingya.com.cn/chat/chatClient/chatbox.jsp?companyID=89232536&configID=100" target="_blank">
          <img class="s" src="@/assets/img/common/ai.png" alt="" />
        </a>
        <a v-if="isMobile" href="http://chat.mingya.com.cn/chat/chatClient/chatbox.jsp?companyID=89232536&configID=100" target="_blank">
          <span class="text">智能客服</span></a>
        <a v-else href="http://chat.mingya.com.cn/chat/chatClient/chatbox.jsp?companyID=89232536&configID=100" target="_blank">
          智能客服</a>
      </div> -->
    </div>
    <transition name="fadeIn" enter-active-class="fadeInUp">
      <div class="back-to-top" @click="backToTop" v-show="showBack"></div>
    </transition>
  </aside>
</template>

<script>
import { isMobile } from '@/helper/utils'
export default {
  data() {
    return {
      isMobile: isMobile(),
      timer: null,
      showBack: false,
      visible: false
    }
  },
  methods: {
    backToTop() {
      window.scrollTo(0, 0)
    }
  },
  mounted() {
    window.addEventListener('scroll', () => {
      if (this.timer) {
        //清空timer
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        const top =
          document.documentElement.scrollTop || document.body.scrollTop
        const clientHeight = document.documentElement.clientHeight
        if (top > clientHeight) {
          this.showBack = true
        } else {
          this.showBack = false
        }
      }, 100)
    })
  }
}
</script>

<style lang="scss" scoped>
.pc-servece {
  font-size: 50px;
  .service {
    width: 0.97rem;
    height: 0.97rem;
    background: linear-gradient(180deg, #ff923b, #ff5e27);
    border-radius: 50%;
    position: fixed;
    bottom: 3.5rem;
    right: 0.23rem;
    color: #fff;
    z-index: 99999;
    padding: 0.1rem;
    text-align: center;
    .hide {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 0.97rem;
      height: 0.97rem;
      bottom: -110%;
      cursor: pointer;
      background-color: #fe6917;
      border-radius: 50%;
      // background-image: url(../../assets/img/common/common_add_white.png);
      // background-size: 50% 50%;
      // background-repeat: no-repeat;
      background-position: center;
      img {
        transform: rotate(45deg);
        width: 45%;
        height: 45%;
        margin-bottom: 0;
      }
    }
    // a {
    //   font-size: 12px;
    //   img {
    //     width: 1.5em;
    //     height: auto;
    //     &.s {
    //       transform: translateY(0.5em);
    //     }
    //   }
    //   .text {
    //     display: block;
    //     white-space: nowrap;
    //     transform: scale(0.7);
    //   }
    // }
  }
  .m-service {
    position: fixed;
    bottom: 3.5rem;
    right: -0.45rem;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0.9rem;
    height: 0.9rem;
    background: linear-gradient(180deg, #ff923b, #ff5e27);
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    img {
      width: .6rem;
    }
  }
}
.mobile-servece {
  font-size: 50px;
  .service {
    width: 0.9em;
    height: 0.9em;
    border-radius: 1.2em;
    bottom: 2.6rem;
    .hide {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 4em;
      height: 4em;
      border-radius: 50%;
      bottom: -110%;
      background-color: #7d7e7e;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        transform: rotate(45deg);
        width: 45%;
        height: 45%;
        margin-bottom: 0;
      }
    }
    a {
      font-size: 12px;
      img {
        width: 1.5em;
        height: auto;
        &.s {
          transform: translateY(0.5em);
        }
        margin-bottom: 0;
      }
      .text {
        display: block;
        white-space: nowrap;
        transform: scale(0.7);
      }
    }
  }
  .back-to-top {
    width: 0.9em;
    height: 0.9em;
  }
  .m-service {
    position: fixed;
    bottom: 1.5rem;
    right: -0.45em;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0.9em;
    height: 0.9em;
    background: linear-gradient(180deg, #ff923b, #ff5e27);
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    img {
      width: 20px;
    }
  }
}

.service {
  width: 0.9rem;
  height: 2.4rem;
  background: linear-gradient(180deg, #ff923b, #ff5e27);
  border-radius: 0.45rem;
  position: fixed;
  bottom: 3rem;
  right: 0.23rem;
  color: #fff;
  z-index: 99999;
  padding: 0.1rem;
  text-align: center;

  div {
    height: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.16rem;
    flex-direction: column;
    &:hover {
      img {
        transform: scale(1.2);
      }
    }
    img {
      width: 0.4rem;
      height: 0.32rem;
      margin-bottom: 0.06rem;
      transition: all 0.8s;
    }
    a {
      text-decoration: none;
      color: #fff;
    }
  }
  .online {
    width: 100%;
    height: 100%;
    // border-bottom: 1px solid #fff;
  }
}
.back-to-top {
  width: 0.97rem;
  height: 0.97rem;
  background-size: 100% 100%;
  background-image: url("../../assets/img/common/back-top-top.png");
  cursor: pointer;
  position: fixed;
  bottom: 1rem;
  right: 0.23rem;
  z-index: 99999;
  transition: all 0.8s;

  &:hover {
    transform: scale(1.2);
  }
}
</style>
